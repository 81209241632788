<template>
  <div class="tab">
    <ul class="groups" v-dragscroll.x>
      <li
      v-for="(tab, index) in tabList"
      :key="index"
      class="tab-button">
        <label
        :for="`${_uid}${index}`"
        v-text="tab"
        class="tab-label"
        :class="{ 'active': index + 1 === activeTab, }"/>
        <input
        :id="`${_uid}${index}`"
        type="radio"
        :name="`${_uid}-tab`"
        :value="index + 1"
        v-model="myActiveTab"
        v-show="false"/>
      </li>
    </ul>
    <template v-for="(tab, index) in tabList">
      <template v-if="!isMaintained">
        <div
        v-dragscroll.y
        :key="index"
        v-if="index + 1 === activeTab"
        class="tab-content">
          <slot :name="`tabPanel-${index + 1}`" />
        </div>
      </template>
      <template v-else>
        <div
        v-dragscroll.y
        :key="index"
        v-show="index + 1 === activeTab"
        class="tab-content">
          <slot :name="`tabPanel-${index + 1}`" />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    tabList: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: Number,
      required: true,
      default: 1,
    },
    isMaintained: { // 탭 클릭시 다른 탭 컨텐츠 유지 여부
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      // activeTab: 1,
    }
  },
  computed: {
    myActiveTab: {
      get () {
        return this.activeTab
      },
      set (a) {
        this.$emit('update:activeTab', a)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .tab {
    box-sizing: border-box;
    overflow: hidden;
    .tab-content{
      flex-grow: 1;
      background: #fff;
      // padding: 5px;
      min-height: 400px;
      box-sizing: border-box;
      overflow: auto;
      // background: #33333333;
    }
    .groups {
      display: flex;
      height: 42px;
      list-style-type: none;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 16px;
      margin: 0;
      overflow: visible;
      .tab-button {
        width:100%;
        min-width:50px;
        font-weight: 500;
        letter-spacing: -0.2px;
        .tab-label {
          font-size: 16px;
          cursor: pointer;
          display: block;
          margin:0;
          padding-bottom: 5px;
          position: relative;
          padding: 0 4px;
          height: 40px;
          color: #A0A0A0;
          line-height: 40px;
          background-color: transparent;
        }
        .active {
          font-weight: 600;
          color: #000;
          &:after {
            display: block;
            position: absolute;
            left: 0;
            bottom: -2px;
            width: 100%;
            height: 2px;
            background-color: #000;
            content: "";
          }
        }
      }
    }
  }

</style>
