<template>
  <div @click.self="close('outside')" class="bg-modal">
    <div class="modal-wrap">
      <div class="modal-header">
        <h3 class="tit" v-html="title"></h3>
        <button type="button" @click="$emit('close')">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>닫기 아이콘</title>
            <path d="M15 1L1 15" stroke="black" stroke-width="1.4" stroke-linecap="round"></path>
            <path d="M15 15L1 0.999999" stroke="black" stroke-width="1.4" stroke-linecap="round"></path>
          </svg>
        </button>
      </div>
      <div class="modal-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Modal',
  props: {
    title: {
      type: String,
      default: String,
    },
    closeOnClickOutside: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close (from) {
      if (from == 'outside' && this.closeOnClickOutside) {
        this.$emit('close')
      }
    },
  },
  mounted () {

  },
}
</script>
<style lang="scss" scoped>

.bg-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.4);
  z-index: 103;
  .modal-wrap {
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFF;
    border-radius: 16px;
    width: calc(min(100% - 32px, 327px));
    .modal-header {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      border-bottom: 1px solid #E6E8EB
    }
    .modal-content {
      padding: 16px 16px 24px;
    }
  }
}
</style>
