import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import PortalVue from 'portal-vue'
import VueKonva from 'vue-konva'
import VCalendar from 'v-calendar'
import VueDragscroll from 'vue-dragscroll'

import isOutside from './directive/isOutside'
import validity from './directive/Validity'
import Popup from './components/common/Popup'
import Modal from './components/common/Modal'
import Dropdown from './components/common/Dropdown'
import PanelLayout from './layout/PanelLayout'
import SpInput from './components/common/input/SpInput'
import SpSelect from './components/common/input/SpSelect'
import SpTabbedPanel from './components/common/input/SpTabbedPanel'
import SpTabContainer from './components/common/SpTabContainer'
import SpTabContent from './components/common/SpTabContent'

import './mixin/KonvaLibs'
import './mixin/Common'
import './assets/scss/common.scss'

Vue.config.productionTip = false
// Vue.config.ignoredElements = [/^ion-/] // https://ionic.io/ionicons

const API_TIMEOUT = 35000
Vue.prototype.$http = axios.create({
  timeout: API_TIMEOUT,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
})

Vue.use(VueDragscroll)
Vue.use(VueKonva)
Vue.use(isOutside)
Vue.use(validity)
Vue.use(PortalVue)
Vue.use(VCalendar)

Vue.component('Popup', Popup)
Vue.component('Modal', Modal)
Vue.component('PanelLayout', PanelLayout)
Vue.component('Dropdown', Dropdown)
Vue.component('SpInput', SpInput)
Vue.component('SpSelect', SpSelect)
Vue.component('SpTabbedPanel', SpTabbedPanel)
Vue.component('SpTabContainer', SpTabContainer)
Vue.component('SpTabContent', SpTabContent)

const v = new Vue({
  router,
  store,
  created () {
    this.$http.interceptors.request.use(
      (config) => {
        const source = axios.CancelToken.source()
        config.cancelToken = source.token
        store.commit('addCancelToken', source)
        store.commit('increaseRequestCount')
        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )
    this.$http.interceptors.response.use(
      this.responseSuccess,
      this.responseError,
    )
  },
  render: (h) => h(App),
})

router.onReady(() => {
  v.$mount('#app')
})
