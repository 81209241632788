<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 24 24" fill="none">
    <title>확인 아이콘</title>
    <circle cx="12" cy="12" r="10" :fill="color"/>
    <path d="M8 12L10.8586 14.8586C10.9367 14.9367 11.0633 14.9367 11.1414 14.8586L16 10" stroke="white" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>
<script>
export default {
  name: 'IconCheck',
  props: {
    size: {
      type: [Number, String],
      default: 24,
    },
    color: {
      type: String,
      default: '#49D437',
    },
  },
}
</script>
