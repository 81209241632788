<template>
    <div class="tab">
        <ul class="groups">
            <li
                v-for="(tab, index) in tabList"
                :key="index"
                class="tab-button">
                <label
                    :for="`${_uid}${index}`"
                    v-text="tab"
                    class="tab-label"
                    :class="{ 'active': index + 1 === activeTab, }"/>
                <input
                    :id="`${_uid}${index}`"
                    type="radio"
                    :name="`${_uid}-tab`"
                    :value="index + 1"
                    v-model="myActiveTab"
                    class="screen_out" />
            </li>
        </ul>
        <slot></slot>
    </div>
</template>
<script>
export default {
  props: {
    tabList: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: Number,
      required: true,
      default: 1,
    },
    isMaintained: { // 탭 클릭시 다른 탭 컨텐츠 유지 여부
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      // activeTab: 1,
    }
  },
  computed: {
    myActiveTab: {
      get () {
        return this.activeTab
      },
      set (a) {
        this.$emit('update:activeTab', a)
      },
    },
  },
}
</script>
<style lang="scss" scoped>
  .tab {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    .groups {
      display: flex;
      height: 42px;
      list-style-type: none;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 16px;
      margin: 0;
      overflow: visible;
      border-bottom: 1px solid #E6E8EB;
      .tab-button {
        width:100%;
        min-width:50px;
        font-weight: 500;
        letter-spacing: -0.2px;
        .tab-label {
          font-size: 16px;
          cursor: pointer;
          display: block;
          margin:0;
          padding-bottom: 5px;
          position: relative;
          padding: 0 4px;
          height: 40px;
          color: #A0A0A0;
          line-height: 40px;
          background-color: transparent;
        }
        .active {
          font-weight: 600;
          color: #000;
          &:after {
            display: block;
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 100%;
            height: 2px;
            background-color: #000;
            content: "";
          }
        }
      }
    }
  }

</style>
