<template>
  <div class="panel-layer">
    <div class="layer-header" v-if="!contentsOnly">
      <h4 class="layer-title">{{ title }}</h4>
      <button
        type="button"
        class="btn-next btn-detail-close"
        aria-label="패널 닫기"
        @click="$emit('close')"
      >
      </button>
    </div>
    <div class="layer-contents">
      <slot name="default"></slot>
    </div>
    <slot name="panelFooter"></slot>
  </div>
</template>
<script>
export default {
  name: 'PanelLayout',
  props: {
    title: {
      type: String,
      default: '',
    },
    contentsOnly: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss" scoped>
.panel-sub {
  overflow: hidden auto;
  position: absolute;
  top: 0;
  left: 0;
}
.panel-layer {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
  .layer-header {
    flex: 0 0 auto;
    position: relative;
    min-height: 54px;
    padding: 17px 15px 14px;
  }
  .layer-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }
  .btn-next {
    position: absolute;
    top: 50%;
    right: 6px;
    display: flex;
    width: 44px;
    height: 44px;
    align-items: center;
    justify-content: center;
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5IDVMNSAxOSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIxLjQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTkgMTlMNSA1IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuNCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=") center center / 24px no-repeat;
    transform: translateY(-50%);
  }
  .layer-contents {
    overflow: hidden auto;
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    // position: relative;
    height: calc((var(--vh, 1vh) * 100) - 54px);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar for Chrome, Safari and Opera */
    }
  }
}
</style>
