<template>
    <PanelLayout :title="title" @close="$emit('close')">
        <slot></slot>
    </PanelLayout>
</template>
<script>
export default {
  name: 'MobileDropdown',
  props: {
    title: {
      type: String,
      default: String,
    },
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>
.panel-layer {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20;
}
</style>
