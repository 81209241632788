import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'

const Error404 = () => import('../components/common/error/Error404.vue')
const Error403 = () => import('../components/common/error/Error403.vue')
const WebMain = () => import('../components/WebMain.vue')
const WebviewMain = () => import('../components/WebviewMain.vue')

Vue.use(VueRouter)
// 모든 주소는 자동으로 /space/ 하위 ( = publicPath)
const routes = [
  {
    path: '*',
    name: 'Error404',
    component: Error404,
    meta: {
      requiresAuth: false,
      fullScreen: false,
    },
  },
  {
    path: '*',
    name: 'Error403',
    component: Error403,
    meta: {
      requiresAuth: false,
      fullScreen: false,
    },
  },
  {
    path: '/webview/:branchId?/floor/:floorType?',
    alias: '/webview/:branchId?',
    name: 'Webview',
    component: WebviewMain,
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
  },
  {
    path: '/:branchId?/floor/:floorType?',
    alias: '/:branchId?',
    name: 'Main',
    component: WebMain,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})
// 엑세스 토큰 보유여부 확인
const isLogined = function () {
  return !!store.state.user.accessToken
}
// 네비게이션 가드
router.beforeEach(async (to, from, next) => {
  // 진행중이던 axios 모두 취소
  store.dispatch('cancelPendingRequests')
  // 로그인이 필요한 페이지여부 확인
  if (to.matched.some(route => route.meta.requiresAuth)) {
    // 로그인 여부 확인
    if (isLogined()) {
      next()
    } else {
      // 엑세스 토큰 없음, 리프레시 토큰 갱신 시도
      if (await store.dispatch('user/refresh')) {
        // 리프레시 시도 성공
        // 페이지 접근 권한 확인 (구현 안 함)
        next()
        // 리프레시 실패 (토큰 없음 or 만료 or 인증서버 로직 에러)
      } else {
        // 로그인 페이지로 리다이렉트
        location.href = `${process.env.VUE_APP_MEMBER_URL}?redirect=${encodeURIComponent(process.env.VUE_APP_URL + to.fullPath)}`
      }
    }
    // 권한이 필요 없는 페이지
  } else {
    next()
  }
})

export default router
