<template>
  <div class="dropdown-wrap">
    <button
    type="button"
    class="category-item"
    :class="{active: isActive}"
    @click="open">
      <span>{{optionText}}</span>
      <svg class="ico" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Icon / S / Expand_more">
          <path id="Vector 209" d="M5.00293 8L9.00293 12L13.0029 8" stroke="#424242" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
      </svg>
    </button>
    <div
    v-if="isActive && !isMobile"
    v-click-outside="close"
    class="dropdown-list"
    :aria-hidden="(!isActive).toString()"
    ref="dropdownList">
      <label v-if="isUseAll">
        <span
        class="label-wrap"
        :class="{active: !selected.length}"
        @click="()=> {
          this.$emit('input', [])
          this.selected = []
        }">
          <span class="checkbox"></span>
          <span class="name">전체</span>
        </span>
      </label>
      <label v-for="(item, index) in options" :key="index">
        <input
        v-if="isUseAll"
        type="checkbox"
        v-model="selected"
        :value="item[valueName]"
        @input="input(item[valueName])"
        :checked="selected.includes(item[valueName])"
        class="screen_out"/>
        <input
        v-else
        type="radio"
        v-model="selected"
        :value="item[valueName]"
        @change="input"
        class="screen_out"/>
        <span class="label-wrap">
          <span class="checkbox"></span>
          <span class="name">{{ item[displayName] }}</span>
        </span>
      </label>
    </div>
    <portal to="mobile-category-panel">
      <MobileDropdown
        v-if="isActive"
        @close="close"
        :title="defaultName"
        class="mobile layer-option">
          <label v-if="isUseAll">
            <span
            class="label-wrap"
            :class="{active: !selected.length}"
            @click="()=> {
              this.selected = []
            }">
              <span class="checkbox"></span>
              <span class="name">전체</span>
            </span>
          </label>
          <label v-for="(item, index) in options" :key="index">
            <input
            v-if="isUseAll"
            type="checkbox"
            v-model="selected"
            :value="item[valueName]"
            @input="input(item[valueName])"
            :checked="selected.includes(item[valueName])"
            class="screen_out"/>
            <input
            v-else
            type="radio"
            v-model="selected"
            :value="item[valueName]"
            @change="input"
            class="screen_out"/>
            <span class="label-wrap">
              <span class="checkbox"></span>
              <span class="name">{{ item[displayName] }}</span>
            </span>
          </label>
        </MobileDropdown>
    </portal>
  </div>
</template>
<script>
import MobileDropdown from '@/components/common/MobileDropdown'
export default {
  name: 'Dropdown',
  components: {
    MobileDropdown,
  },
  data () {
    return {
      isActive: false,
      selected: [],
      windowWidth: null,
    }
  },
  props: {
    defaultName: {
      type: String,
      default: '선택',
    },
    isUseAll: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      default: Array,
    },
    displayName: {
      type: String,
      default: 'text',
    },
    valueName: {
      type: String,
      default: 'value',
    },
    checked: { required: false },
    value: {},
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        if (!this.isUseAll) {
          this.selected = this.value
        }
      },
    },
    isActive (newValue) {
      if (newValue) {
        this.$nextTick(() => {
          if (!this.isMobile) {
            this.$refs.dropdownList.scrollTop = 0
          }
        })
      }
    },
    isCheckedAll (newValue) {
      if (newValue && this.isUseAll) {
        this.$nextTick(() => {
          this.selected = []
        })
      }
    },
  },
  computed: {

    isCheckedAll () {
      if (this.isUseAll) {
        return this.selected.length === this.options.length
      } else {
        return false
      }
    },
    optionText () {
      if (!this.isUseAll) {
        return (this.options.find(o => o[this.valueName] == this.selected)) ? (this.options.find(o => o[this.valueName] == this.selected))[this.displayName] : this.defaultName
      }
      if (this.selected.length === 0) {
        return this.defaultName
      }

      if (!this.selected) {
        return '전체'
      } else {
        let copied = [...this.selected]
        copied = copied.map(e => {
          return this.options.find(o => o[this.valueName] == e)[this.displayName]
        })
        return copied.sort((a, b) => {
          if (!isNaN(a) && !isNaN(b)) {
            return parseInt(a, 10) - parseInt(b, 10)
          } else if (!isNaN(a)) {
            return -1
          } else if (!isNaN(b)) {
            return 1
          } else {
            return a.localeCompare(b, 'ko')
          }
        }).join(', ')
      }
    },
  },
  beforeDestroy () {
  },
  methods: {
    input (e) {
      if (this.isUseAll) {
        const checked = [].concat(this.selected)
        if (!checked.includes(e)) {
          checked.push(e)
        } else {
          const index = checked.indexOf(e)
          if (index !== -1) {
            checked.splice(index, 1)
          }
        }
        if (this.options.length == checked.length) {
          this.$emit('input', [])
        } else {
          this.$emit('input', checked)
        }
      } else {
        this.$emit('input', this.selected)
      }
      this.$emit('change')
    },
    async open () {
      const sleep = (t) => new Promise(resolve => setTimeout(resolve, t))
      await sleep(1)
      this.isActive = true
    },
    async close (e) {
      if (this.isActive) this.isActive = false
    },
    checkOption ($event) {
      const { value } = $event.target
      if (!this.isUseAll) {
        return
      }
      if (value === 'ALL') {
        this.selected = this.selected.filter((item) => item === 'ALL')
        return
      }
      this.selected = this.selected.filter((item) => item !== 'ALL')
    },
  },
}
</script>
<style lang="scss" scoped>
.category-item {
  display: inline-flex;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  margin:0 3px;
  padding: 0 9px 0 15px;
  border-radius:100px;
  border:1px solid #D1D5D9;
  background-color:#fff;
  &.active {
    border:1px solid #636C73;
  }
  .ico {
    // background: red;
    margin: 0 4px;
    height: 34px;
    // float: right;
    flex: 0 0 auto;
  }
}
.dropdown-wrap {
  position: relative;
  display: inline-block;
}
.dropdown-list {
  display: none;
  overflow: hidden auto;
  max-height: calc(min((var(--vh, 1vh) * 100)/3, 310px));
  position: absolute;
  top: 43px;
  padding: 4px;
  background-color: #fff;
  border: 1px solid #d1d5d9;
  border-radius: 4px;
  z-index: 2;
}
.label-wrap {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  min-width: 142px;
  margin-top: 2px;
  padding: 6px 10px;
  font-weight: 400;
  background-color: #fff;
  transition: all 0.3s ease-out;
  border-radius: 4px;
  cursor: pointer;
  .name {
    font-size: 14px;
    line-height: 16px;
  }
  .checkbox {
    display: block;
    width: 24px;
    height: 24px;
  }
  &.active {
    background-color: #f3fbfa;
    transition: background 0.3s ease-out;
    .name {
      font-weight: 700;
    }
    .checkbox {
      background: url('https://static.sparkplus.co/hompage/common/icon/icon_option_checked.svg')
      center center no-repeat;
    }
  }
}
label {
  input {
    &:checked {
      + .label-wrap {
        background-color: #f3fbfa;
        transition: background 0.3s ease-out;
        .name {
          font-weight: 700;
        }
        .checkbox {
          background: url('https://static.sparkplus.co/hompage/common/icon/icon_option_checked.svg')
            center center no-repeat;
        }
      }
    }
  }
}
.layer-option {
  label {
    .label-wrap {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      padding: 15px 16px;
      font-weight: 400;
      background-color: #FFF;
      transition: all 0.3s ease-out;
      cursor: pointer;
      &.active {
        background-color: #f3fbfa;
      }
    }
   .name {
      font-size: 16px;
      line-height: 18px;
    }
    .checkbox {
      display: block;
      width: 24px;
      height: 24px;
    }
  }
}
// MOBILE ONLY
@media screen and (max-width: 768px) {
  .pc{display: none !important;}
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
// PC ONLY
@media screen and (min-width: 769px) {
  .mobile{display: none !important;}
  .dropdown-list {
    &[aria-hidden='false'] {
      display: block;
    }
    &[aria-hidden='true'] {
      display: none;
    }
  }
  .panel-layer {
    display: none;
  }
  .layer-option {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    overflow:auto;
    z-index: 13;
  }
}
</style>
