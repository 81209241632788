
export default {
  namespaced: true,
  state: {
    accessToken: null,
    profileInfo: {
      userName: '',
      profilePhotoAwsS3Url: '',
      userGradeType: '',
      userPhoneNumber: '',
      memberShipInfo: null,
    },
  },
  getters: {
    profile: (state) => state.profileInfo,
  },

  mutations: {
    setAccessToken (state, token) {
      state.accessToken = token
    },
    setProfileInfo (state, profileInfo) {
      state.profileInfo = { ...profileInfo }
    },
  },
  actions: {

    // 토큰 갱신
    async refresh ({ dispatch, commit }) {
      try {
        const response = await this._vm.post(
          `${this._vm.MEMBER_API_URL}refresh`,
        )
        commit('setAccessToken', response.data.accessToken)
        await dispatch('userProfile')
        return true
      } catch (error) {
        return false
      }
    },
    async userProfile ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}mypage/profile`,
          // payload,
        )
        commit('setProfileInfo', response.data.profileInfo)
        if (response.data.profileInfo.userGradeType == 'regular-grade' && response.data.profileInfo.memberShipInfo == null) {
          location.replace('/community/mypage/main/membership_change')
        }
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
