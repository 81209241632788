<template>
    <input
        ref="input"
        :class="{ plaintext: plaintext }"
        :type="type"
        :value="formattedValue"
        :id="uuid"
        @input="input"
        v-on="lisnters"
        v-bind="attrs"
        :disabled="plaintext || disabled" />
</template>
<script>
export default {
  name: 'SpInput',
  inheritAttrs: false,

  props: {
    value: { type: null, default: undefined },
    id: { type: String, default: '' },
    type: { type: String, default: 'text', required: false },
    plaintext: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    breakPoints: { type: Array, default: () => [] },
    delimiter: { type: String, default: '' },
  },
  data () {
    return {
      uuid: null,
    }
  },
  created () {
    this.uuid = this.id
    if (this.uuid == '') {
      this.uuid = this.uuidv4()
    }
  },
  methods: {
    input (event) {
      this.$emit('input', event.target.value)
    },
    focus () {
      this.$refs.input.focus()
    },
  },
  computed: {
    lisnters () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    attrs () {
      return this.$attrs
    },
    formattedValue () {
      let formatted = this.value
      if (this.breakPoints.length && this.delimiter != '') {
        const splitStringByBreakPoints = (str, breakPoints) => {
          const result = []
          let startIndex = 0
          for (let i = 0; i < breakPoints.length; i++) {
            const breakPoint = breakPoints[i]
            const substring = str.substr(startIndex, breakPoint)
            if (substring.length) {
              result.push(substring)
            }
            startIndex += breakPoint
          }
          const remaining = str.substr(startIndex)
          if (remaining.length) {
            result.push(remaining)
          }
          return result
        }
        const realNumber = this.value.replaceAll(this.delimiter, '')
        const delimiteredNumber = splitStringByBreakPoints(realNumber, this.breakPoints)
        formatted = delimiteredNumber.join(this.delimiter)
      }
      return formatted
    },
  },
}
</script>
<style lang="scss" scoped>
input {
  height: 48px;
  padding: 0 14px;
  border-radius: 4px;
  border: 1px solid #e6e8eb;
  background-color: #f9fafa;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 46px;
  outline: none;
  color: #424242;
}
input[type=text] {
  flex: 1 1 auto;
  padding: 0 14px;
  border: 0;
  background-color: #FFF;
  &:disabled {
    background-color: #E6E8EB;
    color: #737373;
  }
}
input::placeholder {
  color: #9E9E9E;
}
</style>
