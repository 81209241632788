<template>
    <select
        :tabindex="attrs.hasOwnProperty('readonly') ? -1 : 0"
        :id="uuid"
        :value="value"
        @input="input"
        v-on="listeners"
        v-bind="attrs">
          <slot></slot>
      </select>
</template>
<script>
export default {
  name: 'SpSelect',
  inheritAttrs: false,
  props: {
    value: { type: null, default: undefined },
    id: { type: String, default: '' },
  },
  data () {
    return {
      uuid: null,
    }
  },
  created () {
    this.uuid = this.id
    if (this.uuid == '') {
      this.uuid = this.uuidv4()
    }
  },
  methods: {
    input (event) {
      this.$emit('input', event.target.value)
    },
  },
  computed: {
    listeners () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    attrs () {
      return this.$attrs
    },
  },
}
</script>
<style lang="scss" scoped>
select {
    display: block;
    width: 100%;
    padding: 0 36px 0 0;
    line-height: 48px;
    height: 50px;
    text-indent: 14px;
    background-color: #FFF;
    border-color: #D1D5D9;
    border-radius: 4px;
    background: url('https://static.sparkplus.co/hompage/common/icon/icon_select_option.svg') top 10px right 12px no-repeat;
    appearance: none;
}
</style>
