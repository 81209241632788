<template>
    <div v-if="isActive" class="tab-content"  v-dragscroll.y>
        <slot></slot>
    </div>
</template>
<script>
export default {
  name: 'SpTabContent',
  props: {
    label: {
      type: String,
      default: String,
    },
  },
  computed: {
    isActive () {
      const { tabList, myActiveTab } = this.$parent
      return tabList[myActiveTab - 1] === this.label
    },
  },
}
</script>
<style lang="scss" scoped>
    .tab-content{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        background: #fff;
        // padding: 5px;
        // min-height: 400px;
        box-sizing: border-box;
        overflow: auto;
        // background: #33333333;
    }
</style>
