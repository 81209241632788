export default {
  namespaced: true,
  state: {
    seatFilter: {},
    workmodules: [],
    meetingRooms: [],
    seats: [],
    meetingRoomFilter: {},
    guestInvitationPolicy: {},
  },
  getters: {
    seatFilter: (state) => state.seatFilter,
    meetingRoomFilter: (state) => state.meetingRoomFilter,
    workmodules: (state) => state.workmodules,
    meetingRooms: (state) => state.meetingRooms,
    seats: (state) => state.seats,
    guestInvitationPolicy: (state) => state.guestInvitationPolicy,
  },

  mutations: {
    setSeatFilter (state, data) {
      state.seatFilter = { ...data }
    },
    setWorkmodules (state, data) {
      state.workmodules = data.branchWorkModuleList || []
    },
    setMeetingRooms (state, data) {
      state.meetingRooms = data.branchMeetingroomWorkModuleList || []
    },
    setSeats (state, data) {
      state.seats = data.seatList || []
    },
    setMeetingRoomFilter (state, data) {
      state.meetingRoomFilter = { ...data }
    },
    setguestInvitationPolicy (state, data) {
      state.guestInvitationPolicy = { ...data }
    },
  },
  actions: {
    async getMeetingRoomFilter ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}reservation/filter/${payload.branchId}/meetingroom`,
          payload,
        )
        commit('setMeetingRoomFilter', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getMeetingRooms ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}reservation/workmodule/${payload.branchId}/meetingroom`,
          payload,
        )
        commit('setMeetingRooms', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postMeetingRoomReservation ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.APP_API_URL}reservation/meetingroom`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async deleteMeetingRoomReservation ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.delete(
          `${this._vm.APP_API_URL}reservation/meetingroom/${payload.reservationId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getSeatFilter ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}reservation/filter/${payload.branchId}/seat`,
        )
        commit('setSeatFilter', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getWorkmodules ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}reservation/workmodule/${payload.branchId}/seat`,
          payload,
        )
        commit('setWorkmodules', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getSeats ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}reservation/seat/${payload.roomId}/seat`,
          payload,
        )
        commit('setSeats', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postSeatReservation ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.APP_API_URL}reservation/seat`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async putSeatReservation ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
          `${this._vm.APP_API_URL}reservation/seat`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getGuestInvitationPolicy ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}reservation/main/${payload.branchId}/guest`,
          payload,
        )
        commit('setguestInvitationPolicy', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postGuestInvitation ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.APP_API_URL}reservation/guest`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
