<template>
<div>
  <popup
  v-for="modal, i in modals"
  :key="i"
  :title="modal.title"
  :description="modal.message"
  :closeOnClickOutside="false">
    <div class="button-group">
      <button
      v-if="modal.actionCode == 'ALERT'"
      class="button big primary"
      @click="onEmit(i, {emit:'alert',value: undefined})">확인</button>
      <button
      v-if="modal.actionCode == 'LOGIN'"
      class="button big primary"
      @click="login(i)">확인</button>

      <button
      v-if="modal.actionCode == 'CONFIRM'"
      class="button big"
      @click="onEmit(i, {emit:'confirm',value: false})">{{ modal.cancelName }}</button>
      <button
      v-if="modal.actionCode == 'CONFIRM'"
      class="button big primary"
      @click="onEmit(i, {emit:'confirm',value: true})">{{ modal.confirmName }}</button>
    </div>
  </popup>
</div>
</template>
<script>
export default {
  name: 'SpModals',
  data () {
    return {

    }
  },
  mounted () {
  },
  methods: {
    onEmit (i, emitKV) {
      this.modals.splice(i)
      this.$root.$emit(emitKV.emit, emitKV.value)
    },
    login (i) {
      this.modals.splice(i)
      window.location.href = this.LOGIN
    },
  },
  computed: {
    modals () {
      return this.$store.state.modals
    },
  },
  watch: {

  },
}
</script>
