<template>
  <svg :width="size" :height="size" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>공지 아이콘</title>
    <circle cx="9" cy="9" r="7.5" :fill="color"/>
    <circle cx="8.99993" cy="12.075" r="0.825" fill="white"/>
    <path d="M9 6V9.75" stroke="white" stroke-width="1.35" stroke-linecap="round"/>
  </svg>
</template>
<script>
export default {
  name: 'IconNotice',
  props: {
    size: {
      type: [Number, String],
      default: 24,
    },
    color: {
      type: String,
      default: '#000',
    },
  },
}
</script>
