export default {
  namespaced: true,
  state: {
    regions: [],
    favoritedBranches: [],
    assets: [],
    existCategories: [],
    categoryPositions: {},
    branchFloorBaseImage: '',
    categories: [],
    asset: {},
    assetImages: [],
    branch: {},
    floorType: '',
  },
  getters: {
    regions: (state) => state.regions,
    favoritedBranches: (state) => state.favoritedBranches,
    assets: (state) => state.assets,
    existCategories: (state) => state.existCategories,
    categoryPositions: (state) => state.categoryPositions,
    branchFloorBaseImage: (state) => state.branchFloorBaseImage,
    categories: (state) => state.categories,
    asset: (state) => state.asset,
    assetImages: (state) => state.assetImages,
    branch: (state) => state.branch,
    floorType: (state) => state.floorType,
  },

  mutations: {
    setRegions (state, data) {
      state.regions = data.regions
      state.favoritedBranches = data.favoritedBranches
    },
    setAssets (state, data) {
      state.assets = data.assets
      state.branch = data.branch
      state.floorType = data.floorType
      state.existCategories = data.existCategories
      state.categoryPositions = data.categoryPositions
      state.branchFloorBaseImage = data.branchFloorBaseImage
    },
    setAsset (state, data) {
      state.asset = { ...data.asset }
      state.assetImages = data.assetImages
    },
    setBranch (state, data) {
      state.branch = { ...data.branch }
    },
    setCategories (state, data) {
      state.categories = data.categories
    },
    setFloorType (state, floorType) {
      state.floorType = floorType
    },
  },
  actions: {
    async getAsset ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}community/space/asset/${payload.assetId}`,
        )
        commit('setAsset', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getBranch ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}community/space/branch`,
          payload,
        )
        commit('setBranch', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getRegions ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}community/space/regions`,
        )
        commit('setRegions', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getAssets ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}community/space/assets`,
          payload,
        )
        commit('setAssets', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getCategories ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}community/space/categories`,
        )
        commit('setCategories', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        }
        throw error
      }
      return response
    },
    async setFavoriteBranch ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.APP_API_URL}maps/branch-favorite/${payload.branchId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        }
        throw error
      }
      return response
    },
    async deleteFavoriteBranch ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.delete(
          `${this._vm.APP_API_URL}maps/branch-favorite/${payload.branchId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        }
        throw error
      }
      return response
    },
  },
}
